import axiosClient from "@/axios";

export const DocumentService = {
   
  getData(user_id) {
      console.log("DocumentService.getData");
      console.log("user_id: ", user_id);
      console.log(user_id);
      // Chain the calls correctly
      return axiosClient.get('/sanctum/csrf-cookie').then(() => {
          return axiosClient.post('/user/documents', {
              'user_id': user_id
          });
      }).then(response => {
          console.log("response.data");
          console.log(response.data.data);
          // Make sure response.data is an array before returning
          if (Array.isArray(response.data.data)) {
              // TODO: Status
              // if document path employer does not exists => set the not succesful status
              // response.data = response.data.map((item) => ({ ...item, status: this.getStatus(item.created_at, item.email_verified_at), address_full: this.getFullAddress(item.addresses_primary)})); // response.data.data.push({'status': status});
              console.log("response.data after mapping");
              console.log(response.data.data);
              return response.data.data;
          } else {
              throw new Error('Data is not an array, it is ' + typeof response.data.data);
          }
      }).catch(error => {
          console.error(error);
          throw error; // Rethrow the error to ensure it propagates
      });
  },
  getDocumentsSmall() {
      return Promise.resolve(this.getData().slice(0, 10));
  },

  getDocumentsAll(user_id) {
      console.log("DocumentService.getDocumentsAll");
      console.log(user_id);
      return this.getData(user_id);
  },

  getDocumentsLarge() {
      return Promise.resolve(this.getData().slice(0, 200));
  },

  getDocumentsXLarge() {
      return Promise.resolve(this.getData());
  },

  deleteDocument(id) {
    return axiosClient.delete(`/user/documents/${id}`);
  }
}
