<script>
export default {
  data() {
    return {
      pageTitle: ''
    };
  }
};

</script>

<script setup>
import { computed, ref } from "vue";

import BaseInput from "@/components/BaseInput.vue";
import BaseBtn from "@/components/BaseBtn.vue";
import LogoTitle from "@/components/pieces/LogoTitle.vue";
import axiosClient from "@/axios";
import { useRouter, useRoute } from "vue-router";
import { setToken, setUser } from "@/store/mutations";
import {useStore} from "vuex";
import {userStore} from "@/store/userStore";
import {useGlobalErrorStore} from "@/store/globalErrorStore";
import { useCommonUserMessage } from "@/store/commonMsgStore";
import { onMounted } from "vue";

const userData = userStore();
const globalError = useGlobalErrorStore();
const store = useStore();

const pageTitle = ref('Login Page');
let loading = ref(false);
const success = ref('');
let errors = ref({});
let programErrors = ref([]);
let programErrorExists = ref(false);
const route = useRoute(); // Retrieve the current route object
const successMessage = ref(''); // Define a ref to hold the success message

onMounted(() => {
  // Check if there is a success message in the query parameters
  if (route.query.success) {
    successMessage.value = route.query.success;
  }
});

console.log("VITE_APP_URL:" + import.meta.env.VITE_APP_URL);
console.log("VITE_APP_API_URL:" + import.meta.env.VITE_APP_API_URL);

const user = {
    email: '',
    password: '',
    remember: false
}

let router = useRouter();

async function login() 
{  
  console.log("user:");
  console.log(user);

  console.log("clearing old cookies");
  const csrfToken = document.head.querySelector('meta[name="csrf-token"]');

  // manually removing cookies
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
          var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
          var p = location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
          };
          d.shift();
      }
  }

  await axiosClient.get('/sanctum/csrf-cookie');
  console.log("sanctum cookie received.");
  
  await axiosClient.post('/login', user)
    .then(response => {
      success.value = response.data.message;
      sessionStorage.setItem('global_program_error', false);
      setToken(response.data.token);
    })
    .catch(error => {
      if(error.response) {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          errors.value = error.response.data.errors
        } else {
          console.log("Other than 422 error received.");
          console.log(error.response.data.errors);
          let newError = {message: "Systém je dočasně nedostupný. Prosím, zkuste později nebo nás kontaktuje pro více informací.", type: "other_than_422"};
          programErrors.value.push(newError); // .value needs to be there since "ref" is used!!!
          throw new Error(error);
        }
      } else {
        // TODO: Is this OK?
        console.log("Other than 422 error received.");
        console.log(error);
        console.log("typeof error:");
        console.log(typeof error);
        let newError = {message: "Systém je dočasně nedostupný. Prosím, zkuste později nebo nás kontaktuje pro více informací.", type: "other_than_422"};
        programErrors.value.push(newError); // .value needs to be there since "ref" is used!!!
        throw new Error(error);
      }
  });

  console.log("login done.");

  let {data} = await axiosClient.get('/api/user');
  let token = '1234';
  console.log("data:");
  console.log(data);

  userData.setUserToken(token);
  sessionStorage.setItem('user_email', data.email);
  let user_fullname = data.firstname + ' ' + data.lastname;
  sessionStorage.setItem('user_full_name', user_fullname);
  userData.setUserData(user_fullname, data.email);

  console.log("userData:");
  console.log(userData);
  
  store.commit('setUser', data);
  store.commit('setToken', token);
  sessionStorage.setItem('TOKEN', token);
  
  
  router.push({ name: 'dashboard' }).catch(error => {
    console.error(error);
  });
    
  // await axiosClient.get('/sanctum/csrf-cookie');

}

const hasAnyError = computed(() => {

    const commonUsrMessageStore = useCommonUserMessage();

    // debug
    console.log("hasAnyError:");
    console.log(errors.value);
    console.log("programErrors.value:");
    console.log(programErrors);

    if (Object.keys(programErrors.value).length > 0) {
        console.log("programErrors.value:");
        console.log(programErrors.value);
        if(programErrors.value[0]['message']) {
          console.log("programErrors['message']");
          console.log(programErrors.value[0]['message']);
          return programErrors.value[0]['message'];
        }
    }

    console.log("globalError.isProgramErrorSet:");
    console.log(globalError.isProgramErrorSet);

    if (sessionStorage.getItem('global_program_error') === true || globalError.isProgramErrorSet) {
      console.log("commonUsrMessageStore:");
      console.log(commonUsrMessageStore.getProgramErrorMsg);
      return commonUsrMessageStore.getProgramErrorMsg;
    }

    if(Object.keys(errors.value).length > 0){
        console.log("errors.value:");  
        console.log(errors.value);

        if (errors.value['email']) {
            return errors.value['email'][0];
        } else if (errors.value['password']) {
          return errors.value['password'][0];
        }
        else {
          throw new Error('Unexpected error when reading credential response.');
        }
    }

    return false;
});

function passwordVisible() {
  var x = document.getElementById("password");
  if (x.type === "password") {
    x.type = "text";  
  } else {
    x.type = "password";
  }
}
</script>


<template>
  <LogoTitle :title="pageTitle"></LogoTitle>

  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" action="#" method="POST" @submit.prevent="login">
        <BaseInput
          type="email"
          label="E-mail"
          name="email"
          v-model="user.email"
          autocomplete="email"
          placeholder="Váš e-mail použitý pro registraci"
          class="mb-2"
          required
        />
        <BaseInput
          type="password"
          label="Heslo"
          name="password"
          v-model="user.password"
          class=""
          autocomplete
          required
        />
        <div style="margin-top: 0">
          Zobraz heslo
          <input
          type="checkbox"
          label="Náhled hesla"
          @click="passwordVisible()"
        />
        </div>
        
        <div class="flex justify-between items-center">
          <RouterLink
            :to="{ name: 'forgotPassword' }"
            class="hover:underline text-sm text-gray-400"
          >
            Zapomněli jste heslo?
          </RouterLink>
        </div>
        <div>
          <BaseBtn id="login-btn" type="submit" text="Přihlásit" />
        </div>
        <div v-if="hasAnyError" class="text-red-600">
          {{ hasAnyError }}
        </div>
      </form>

      <!-- ... (your other template code) -->
      <div id="success-registration" v-if="successMessage" class="text-green-600">
        {{ successMessage }}
      </div>
      <!-- ... (the rest of your template code) -->

      <p class="mt-10 text-center text-sm text-gray-500">
        Nejste zaregistrováni?
        {{ ' ' }}
        <router-link
            :to="{name: 'register'}"
            class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
          Zaregistrovat se
        </router-link>
      </p>
    </div>
  </div>
</template>


<style scoped>

</style>

