import Dashboard from "@/views/Dashboard.vue";
import EmployeesManagement from "@/views/EmployeesManagement.vue";
import InvitationsManagement from "@/views/InvitationsManagement.vue";
import EventsManagement from "@/views/EventsManagement.vue";
import Invite from "@/views/Invite.vue";
import Calendar from "@/views/Calendar.vue";
import {createRouter, createWebHistory} from 'vue-router';
import Login from "../views/Login.vue";
import Register from "@/views/Register.vue";
import DefaultLayout from "@/components/DefaultLayout.vue";
import Event from "@/views/Event.vue";
import Document from "@/views/Document.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import store from "@/store";
import ResetPassword from "@/views/ResetPassword.vue";
import ForgotAdminPassword from "@/views/admin/ForgotAdminPassword.vue";
import NoPermission from "@/views/errors/NoPermission.vue";
import axiosClient from "@/axios";
import { ref } from "vue";
import { userStore } from "@/store/userStore";
import ResetUserPassword from "@/views/ResetUserPassword.vue";

const userRole = ref({});

const guard = async function(to, from, next) {

    // check for valid auth token
    await axiosClient.get('/sanctum/csrf-cookie').then(response => {
        console.log("sanctum cookie received.");
        console.log("response:");
        console.log(response);
    });
    await axiosClient.get('/get-permissions')
    .then(response => {
        if(response.data.includes('see_admin_sections') || response.data.includes('see_superadmin_sections')) {
            next();
        } else {
            next({name: 'noPermission'});
        }
    }).catch(error => {
        // There was an error so redirect
        console.log("Error in get-permissions");
        console.log(error);
        next({name: 'noPermission'});
    });
  };

const routes = [
{
        path: '/',
        name: 'home',
        component: DefaultLayout,
        meta: {requiresAuth: true},
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                meta: { requiresAuth: true },
                component: Dashboard
            },
            {
                path: '/sprava-zamestnancu',
                name: 'employeesManagement',
                meta: { requiresAuth: true },
                component: EmployeesManagement,
                beforeEnter: (to, from, next) => {
                    console.log("Before entering the admin page...");
                    guard(to, from, next);
                }
            },
            {
                path: '/pozvanky-smeny',
                name: 'invitationsManagement',
                meta: { requiresAuth: true },
                component: InvitationsManagement,
                beforeEnter: (to, from, next) => {
                    console.log("Before entering the admin page...");
                    guard(to, from, next);
                }
            },
            {
                path: '/sprava-akci',
                name: 'eventsManagement',
                meta: { requiresAuth: true },
                component: EventsManagement,
                beforeEnter: (to, from, next) => {
                    console.log("Before entering the admin page...");
                    guard(to, from, next);
                }
            },
            {
                path: '/invite',
                name: 'invite',
                meta: { requiresAuth: true },
                component: Invite,
                beforeEnter: (to, from, next) => {
                    console.log("Before entering the admin page...");
                    guard(to, from, next);
                }
            },
            {
                path: '/calendar',
                name: 'calendar',
                meta: { requiresAuth: true },
                component: Calendar
            },
            {
                path: '/my-events',
                name: 'myEvents',
                meta: { requiresAuth: true },
                component: Event
            },
            {
                path: '/my-documents',
                name: 'myDocuments',
                meta: { requiresAuth: true },
                component: Document
            },
            {   
                path: "/password-user-reset",
                name: "resetUserPassword",
                meta: { requiresAuth: true },
                component: ResetUserPassword,
            },
            {
                path: '/zmena-hesla-admin',
                name: 'adminResetPassword',
                meta: { requiresAuth: true },
                component: ForgotAdminPassword,
                // https://stackoverflow.com/questions/46262094/checking-auth-token-valid-before-route-enter-in-vue-router
                beforeEnter: (to, from, next) => {
                    console.log("Before entering the admin page...");
                    guard(to, from, next);
                }
            },
            {
                path: '/no-permission',
                name: 'noPermission',
                meta: { requiresAuth: true },
                component: NoPermission
            },
        ]
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: { requiresAuth: false },
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: ForgotPassword,
        meta: { requiresAuth: false },
    },
    {
        path: "/password-reset",
        name: "resetPassword",
        component: ResetPassword,
        meta: { requiresAuth: false },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
    
router.beforeEach((to, from, next) => {
    const userData = userStore();

    console.log("router.beforeEach: ");
    console.log("to: ");
    console.log(to);
    console.log("from: ");
    console.log(from);
    console.log("next: ");
    console.log(next);

    if (to.meta.requiresAuth && !userData.getToken) {
        console.log("No store.state.user.token found, redirecting to login...");
        next({name: 'login'});
    } else if (to.meta.requiresGuest && userData.getToken) {
        console.log("store.state.user.token found, redirecting to dashboard...");
        next({name: 'dashboard'});
    } else {
        console.log("else in beforeEach router: ");
        next();
    }
});

  
export default router;
