import axiosClient from "@/axios";

export const EmployeeService = {
    getStatus(created_at, email_verified) {

        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        const created_at_date = new Date(Date.parse(created_at));
        
        // DEBUG
        // console.log("created_at_date: ", created_at_date);
        // console.log("created_at: ", created_at);
        // console.log("email_verified: ", email_verified);
        // console.log(typeof created_at_date);
        if (email_verified === false || email_verified === null) {
            return 'neaktivní';
        } else {
            if (created_at_date > thirtyDaysAgo) {
                return 'nový';
            } else {
                return 'aktivní';   
            }
        }
    },
    getFullAddress(address) {
        console.log("address: ");
        console.log(address);
        if (address.length > 0) {
            console.log(address[0]);
            console.log(address[0].street + ' ' + address[0].house_number + ', ' + address[0].city + ' ' + address[0].postal_code);
            return address[0].street + ' ' + address[0].house_number + '/' + address[0].orientation_number + ', ' + address[0].city + ' ' + address[0].postal_code;
        } else {
            return '';
        }
    },
    getBirthDate(birth_date) {
        console.log("Parsing date:");
        console.log(birth_date);
            // Convert the string to a Date object
        const date = new Date(birth_date);
        
        // Format the date as YYYY-MM-DD
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // getUTCMonth() returns 0-11
        const day = date.getUTCDate().toString().padStart(2, '0');
        
        // Combine the formatted parts
        let birth_date_edited = `${year}-${month}-${day}`;
        return birth_date_edited;
    },

    getData() {
        console.log("EmployeeService.getData");
        // Chain the calls correctly
        return axiosClient.get('/sanctum/csrf-cookie').then(() => {
            return axiosClient.get('/admin/users');
        }).then(response => {
            console.log("response.data.data");
            console.log(response.data.data);
            // Make sure response.data is an array before returning
            if (Array.isArray(response.data.data)) {
                response.data.data = response.data.data.map((item) => ({ ...item, birth_date: this.getBirthDate(item.birth_date), status: this.getStatus(item.created_at, item.email_verified_at), address_full: this.getFullAddress(item.addresses_primary)})); // response.data.data.push({'status': status});
                console.log("response.data.data after mapping");
                console.log(response.data.data);
                return response.data.data;
            } else {
                throw new Error('Data is not an array, it is ' + typeof response.data);
            }
        }).catch(error => {
            console.error(error);
            throw error; // Rethrow the error to ensure it propagates
        });
    },

    getLazyData(params) {
        console.log("EmployeeService.getLazyData");
        return axiosClient.get('/sanctum/csrf-cookie').then(() => {
            // Prepare the query parameters
            const queryParams = {
                page: params.page,
                rows: params.rows,
                sortField: params.sortField,
                sortOrder: params.sortOrder,
            };
    
            // Add global filter if it exists
            if (params.filters && params.filters.global && params.filters.global.value) {
                queryParams.global = params.filters.global.value;
            }
    
            // Add other specific filters
            if (params.filters) {
                Object.keys(params.filters).forEach(key => {
                    if (key !== 'global' && params.filters[key].value) {
                        console.log("key: ", key);
                        console.log("queryParams: ",queryParams);
                        console.log("queryParams[key]: ", queryParams[key]);
                        console.log("params.filters[key].value: ", params.filters[key].value);
                        queryParams['field'] = key;
                        queryParams['keyword'] = params.filters[key].value;
                    }
                });
            }
    
            // Pass the prepared parameters to your backend
            return axiosClient.get('/admin/users', { params: queryParams });
        }).then(response => {
            console.log("response.data");
            console.log(response.data);
            if (Array.isArray(response.data.data)) {
                response.data.data = response.data.data.map((item) => ({
                    ...item,
                    status: this.getStatus(item.created_at, item.email_verified_at),
                    address_full: this.getFullAddress(item.addresses_primary),
                    birth_date: this.getBirthDate(item.birth_date)
                }));
                return {
                    data: response.data.data,
                    total: response.data.total // Assuming your API returns total count
                };
            } else {
                throw new Error('Data is not an array, it is ' + typeof response.data.data);
            }
        }).catch(error => {
            console.error(error);
            throw error;
        });
    },
    
    getEmployeesSmall() {
        return Promise.resolve(this.getData().slice(0, 10));
    },

    getEmployeesAll() {
        return this.getData();
    },

    getEmployeesLarge() {
        return Promise.resolve(this.getData().slice(0, 200));
    },

    getEmployeesXLarge() {
        return Promise.resolve(this.getData());
    },

    deleteEmployee(id) {
        return axiosClient.delete(`/admin/users/${id}`);
    }

    /*
    getEmployees(params) {
        const queryParams = params
            ? Object.keys(params)
                  .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                  .join('&')
            : '';

        return fetch('https://www.primefaces.org/data/Employees?' + queryParams).then((res) => res.json());
    }
    */
};
