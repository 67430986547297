export const AttributesService = {
    getTreeNodesData() {
        return [
            {
                key: '0',
                label: 'Documents',
                data: 'Documents Folder',
                icon: 'pi pi-fw pi-inbox',
                children: [
                    { key: '0-0-0', label: 'Smlouva.pdf', icon: 'pi pi-fw pi-file', data: '#', type: 'url' },
                    { key: '0-0-1', label: 'Smlouva-1.pdf', icon: 'pi pi-fw pi-file', data: '#', type: 'url' },
                    { key: '0-0-1', label: 'Souhlas-o-zasilani-mzdy.pdf', icon: 'pi pi-fw pi-file', data: '#', type: 'url' },
                ]
            },
            {
                key: '1',
                label: 'Akce',
                data: 'Složka akcí',
                icon: 'pi pi-fw pi-calendar',
                children: [
                    { key: '1-0', label: 'Turné Leoš Mareš, Plzeň, Pilsner aréna, Českomoravská 465', icon: 'pi pi-fw pi-calendar-plus', data: 'Turné Leoš Mareš, Plzeň, Pilsner aréna, Českomoravská 465' },
                    { key: '1-1', label: 'Turné Olympic, Brno, Fléda', icon: 'pi pi-fw pi-calendar-plus', data: 'Turné Olympic, Brno, Fléda' },
                    { key: '1-2', label: 'Tatrovácký festiválek 2024, Kopřivnice, Zkušební polygon Tatra', icon: 'pi pi-fw pi-calendar-plus', data: 'Tatrovácký festiválek 2024, Kopřivnice, Zkušební polygon Tatra' }
                ]
            }
        ];
    },

    getTreeDocumentsData() {
        return [
            {
                key: '0',
                label: 'Smlouva.pdf',
                data: 'Smlouva.pdf',
                icon: 'pi pi-fw pi-file',
                children: [
                    { key: '0-0-0', label: 'Smlouva.pdf', icon: 'pi pi-fw pi-file', data: '#' , type: 'url'},
                    { key: '0-0-1', label: 'Smlouva-1.pdf', icon: 'pi pi-fw pi-file', data: '#', type: 'url' },
                ]
            }, {
                key: '1',
                label: 'Souhlas-o-zasilani-mzdy.pdf',
                data: 'Souhlas-o-zasilani-mzdy.pdf',
                icon: 'pi pi-fw pi-file',
                children: [
                    { key: '0-0-0', label: 'Souhlas-o-zasilani-mzdy.pdf', icon: 'pi pi-fw pi-file', data: '#', type: 'url' },
                ]
            }
        ];
    },

    getTreeNodes() {
        return Promise.resolve(this.getTreeNodesData());
    },

    getTreeDocuments() {
        return Promise.resolve(this.getTreeDocumentsData());
    }
};
