<script setup>

import {onMounted, ref, computed, toRaw} from "vue";
import { FilterMatchMode } from 'primevue/api';
import { EmployeeService as EmployeeService } from '@/services/EmployeeService';
import { DocumentService as DocumentService } from '@/services/DocumentService';
import { AttributesService } from '@/services/AttributesService';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useToast } from 'primevue/usetoast';
import BaseInput from "@/components/BaseInput.vue";
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { reactive } from "vue";
import InputMask from 'primevue/inputmask';
import BaseBtn from "@/components/BaseBtn.vue";
import { userStore } from "@/store/userStore";
import axiosClient from "../axios";
import AddressComponent from "@/components/AddressComponent.vue";
import DataTable from "primevue/datatable";
import FileUpload from "primevue/fileupload";
import Toast from "primevue/toast";
import { createLogger, useStore } from "vuex";
import Checkbox from 'primevue/checkbox';

let errors = ref({});
let programError = ref(false);

const toast = useToast();
const authError = '';
const dynamicUserData = reactive({});

const selectCountryOptions = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'}
];

const selectCountryOptionsSecondary = [
    {code: 'CZ', country_secondary: 'Česká republika'},
    {code: 'SK', country_secondary: 'Slovensko'}
];

const users = ref();
const lazyLoading = ref(true);
const imgUploaded = ref(false);
let loading = ref(false);

const lazyParams = ref({
  first: 0,
  rows: 10,
  page: 1,
  sortField: null,      
  sortOrder: null,
  filters: {},
});

const totalRecords = ref(0);
let userEditSuccesful = ref(false);

const loadLazyData = () => {
  lazyLoading.value = true;
  EmployeeService.getLazyData({
    page: lazyParams.value.page,
    rows: lazyParams.value.rows,
    sortField: lazyParams.value.sortField,
    sortOrder: lazyParams.value.sortOrder,
    filters: filters.value  // Make sure this is included
  }).then((data) => {
    users.value = data.data;
    totalRecords.value = data.total;
    lazyLoading.value = false;
  });
};

const onPage = (event) => {
  lazyParams.value.first = event.first;
  lazyParams.value.rows = event.rows;
  lazyParams.value.page = event.page + 1;
  loadLazyData();
};

const onSort = (event) => {
  lazyParams.value.sortField = event.sortField;
  lazyParams.value.sortOrder = event.sortOrder;
  loadLazyData();
};

const onFilter = (event) => {
    lazyParams.value = {
    ...lazyParams.value,
    filters: event.filters,
    page: 1 // Reset to first page when filtering
  };
  loadLazyData();
};

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    address_primary: { value: null, matchMode: FilterMatchMode.CONTAINS },
    attachements: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    document: { value: null, matchMode: FilterMatchMode.CONTAINS },
    birth_place: { value: null, matchMode: FilterMatchMode.CONTAINS },
    health_insurance_company: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

// TODO: Incorporate the real images
const attachements = ref([
    { name: 'Amy Elsner', image: 'amyelsner.png' },
    { name: 'Anna Fali', image: 'annafali.png' },
    { name: 'Asiya Javayant', image: 'asiyajavayant.png' },
    { name: 'Bernardo Dominic', image: 'bernardodominic.png' },
    { name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
    { name: 'Ioni Bowcher', image: 'ionibowcher.png' },
    { name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
    { name: 'Onyama Limba', image: 'onyamalimba.png' },
    { name: 'Stephen Shaw', image: 'stephenshaw.png' },
    { name: 'XuXue Feng', image: 'xuxuefeng.png' }
]);


const allowSendingSalary = computed({
  get() {
    return Boolean(user.value.bank_account.allow_sending_salary);
  },
  set(value) {
    user.value.bank_account.allow_sending_salary = value ? 1 : 0;
  }
});

// TODO: More statuses. PRIORITY: VERY HIGH
// const statuses = ref(['zakázán', 'aktivní', 'nový', 'pozastavený', 'obnovený', 'nedokončený']);
const statuses = ref(['aktivní', 'nový', 'pozastavený', 'obnovený', 'neaktivní']);
const selectedStatus = ref();
let documentTypes = ref([]);
let selectedDocumentType = ref(null);

let fileWasSelected = ref(false);
let fileWasUploaded = ref(false);
let fileWasUploadedError = ref(false);

let addressErrors = ref({});
const success = ref('');
let responseData = ref({});

let uploadedFile = reactive(null);
let userUpdateSuccesful = ref(false);
let fileUploadError = ref({});
const fileUpload = ref(null);

const user = ref({});
const employeeDialog = ref(false);

const store = useStore();

onMounted(async () => {
  try {
    const response = await getDocumentTypes();
    documentTypes.value = response;
    console.log("documentTypes:");
    console.log(documentTypes.value);
  } catch (error) {
    console.error("Error fetching document types:", error);
  }

  // Other onMounted logic...
  await loadLazyData();
  AttributesService.getTreeNodes().then(data => nodes.value = data);
  AttributesService.getTreeDocuments().then(data => documents.value = data);
});

const getEmployees = (data) => {
    return [...(data || [])].map((d) => {
        if (d.created_at) {
        d.created_at = new Date(d.created_at);
        if (isNaN(d.created_at.getTime())) {
            console.warn(`Invalid created_at for employee ${d.id}: ${d.created_at}`);
            d.created_at = null; // or set to a default created_at
        }
    }
    return d;
  });
};

async function getDocumentTypes() {
  console.log("Fetching document types")
  
  await axiosClient.get('/sanctum/csrf-cookie')
  
  try {
    const response = await axiosClient.get('/admin/documents/document-types')
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
const formatDate = (value) => {
    if (!value) {
        // Handle the case where the value is null, undefined or an empty string
        return '';
    }
    const created_at = new Date(value);
    if (isNaN(created_at)) {
        // Handle the case where the value is not a valid created_at string
        return 'Invalid Date';
    }
    return created_at.toLocaleDateString('cs', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};

const getSeverity = (status) => {
    switch (status) {
        case 'zakázán':
            return 'danger';

        case 'aktivní':
            return 'success';

        case 'nový':
            return 'info';

        case 'pozastavený':
            return 'warning';

        case 'obnovený':
            return null;
    }
}

function onCountryCodeChange(newCountryCode) {   
  console.log("Here in onCountryCodeChange", newCountryCode);
  let newPrefix = '';
  if (newCountryCode === 'CZ') {
    console.log("CZ phone will be used.");
    newPrefix = '420';
  } else if (newCountryCode === 'SK') {
    console.log("SK phone will be used.");
    newPrefix = '421';
  }

  // Update phone number only if it does not already start with the new prefix
  if (newPrefix && !employee.phoneNumber.startsWith(newPrefix)) {
    console.log("Updating phone number.");
    employee.phoneNumber = newPrefix;
    console.log("Updated phone number: ", employee.phoneNumber); // Check the updated value
  }
}; // Set immediate to false to not execute on the initial setup

const nodes = ref(null);
const documents = ref(null);


const expandedKeys = ref({});

const expandAll = () => {
    for (let node of nodes.value) {
        expandNode(node);
    }

    expandedKeys.value = { ...expandedKeys.value };
};

const collapseAll = () => {
    expandedKeys.value = {};
};

const expandNode = (node) => {
    expandedKeys.value[node.key] = true;

    if (node.children && node.children.length) {
        for (let child of node.children) {
            expandNode(child);
        }
    }
};


const saveEmployee = async () => {
    // Assuming that 'employee' is a reactive object like 'employee'
    // Assuming that 'user' is a reactive object
    let employeeData = {
        firstname: user.value.firstname,
        lastname: user.value.lastname,
        email: user.value.email,
        address: { ...user.value.address },
        address_secondary: { ...user.value.address_secondary },
        personal_number: { ...user.value.personal_number },
        birth_date: user.value.birth_date,
        // Further fields can be added here as per the model
    };

    try {
        const updateResponse = await updateEmployee();
        console.log("updateResponse");
        console.log(updateResponse);
        console.log(updateResponse.value[1]);
        const responseText = updateResponse.value[0];

        if (updateResponse.value[1] === 204 || updateResponse.value[1] === 200) {
            toast.add({ severity: 'success', summary: 'Uložení úspěšné', detail: responseText, life: 3000 });

            // Close the dialog and reset the form
            employeeDialog.value = false;
            resetEmployeeForm();

            EmployeeService.getEmployeesAll().then((data) => {
                users.value = getEmployees(data);
                loading.value = false;
            });

            reloadData();
        } else {
            // Check if there are any errors
            if (hasAnyError.value) {
                // Don't submit the form if there are errors
                toast.add({ severity: 'error', summary: 'Chyba při aktualizaci zaměstnance', detail: responseText, life: 3000 });
                return;
            } else {
                toast.add({ severity: 'error', summary: 'Chyba při aktualizaci zaměstnance', detail: responseText, life: 3000 });
            }
        }
    } catch (error) {
        console.error(error);
        toast.add({ severity: 'error', summary: 'Chyba při aktualizaci zaměstnance', detail: 'Nepodařilo se uložit zaměstnance', life: 3000 });
    }
};

const reloadData = () => {
    loadLazyData();
};

async function updateEmployee() {
    // Resources: https://laraveldaily.com/post/laravel-vue-how-to-display-validation-errors

    if(fileWasSelected.value === true && fileWasUploaded.value === false) {
        fileWasUploadedError.value = true;
        return;
    }

    const userData = userStore();

    errorMessages = []; // empty the error message

    store.commit('setToken', null);
    store.commit('setUser', null);
    console.log(user);
    console.log("Update 18:25");

    await axiosClient.get('/sanctum/csrf-cookie').catch(error => {
        console.log(error);
        programError.value = true;
    });

    const employeeData = toRaw(user.value);
    console.log("dynamicUserData:");
    console.log(dynamicUserData);
    employeeData['img_path'] = dynamicUserData.img_path;
    console.log("employeeData after dynamicUserData:");
    console.log(employeeData);

    console.log("employeeData:");
    console.log(employeeData);

    await axiosClient.put('/user/update', employeeData)
        .then(response => {
            console.log("Register response:");
            console.log(response);
            console.log(response.data);

            success.value = response.data.message;
            userUpdateSuccesful.value = true;
            console.log("employeeData.id:");
            console.log(employeeData.id);
            
            responseData.value = response.data;
            console.log("responseData:");
            console.log(responseData);

            userData.setId(employeeData.id);

        })
        .catch(error => {
            if(error.response) {
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                } else {
                    programError.value = true;
                    console.log("Other error:");
                    console.log(error);
                }   
            } else {
                console.log("Error: " + error);
                programError.value = true;
            }

        });


    if(fileWasUploaded.value === true && userUpdateSuccesful.value === true) {

        console.log("Updating img.");
        
        // let user_id = responseData['user']['id'];

        console.log("userData:");
        console.log(userData);

        let user_id = user.value.id;
        console.log("User ID: " + user_id);
        
        await axiosClient.get('/sanctum/csrf-cookie')
        .catch(error => {
            console.log(error);
            programError.value = true;
            userUpdateSuccesful.value = false;
        });
        console.log("File was uploaded. Trying to store it.");
    } 
    
    return responseData;
};

const deleteDocument = async (document, index) => {
  if (confirm(`Are you sure you want to delete this document?`)) {
    try {
      const response = await DocumentService.deleteDocument(document.id);
      if (response.status === 200) {
        user.value.employer_documents.splice(index, 1);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Document deleted successfully', life: 3000 });
        reloadData(); // Assuming you have this method to refresh the current page
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete document', life: 3000 });
    }
  }
};


const resetEmployeeForm = () => {
    // Reset the employee form to its initial state
    user.value = {
        // Reset all properties to their initial values
        firstname: '',
        lastname: '',
        email: '',
        address: { country: '', countryCode: '', city: '', street: '', postal_code: '', house_number: '', orientation_number: '' },
        address_secondary: { country: '', countryCode: '', city: '', street: '', postal_code: '', house_number: '', orientation_number: '' },
        personal_number: { part_1: '', part_2: '' },
        birth_date: '',
        // ... other fields
    };
};

let errorMessages = reactive([]);

const hasAnyError = computed(() => {
    console.log("hasAnyError:");
    console.log(errors.value);
    // debug
    if(errors.value) {
        if(errors.value['personal_number_part_1']) {
            console.log("errors.value['personal_number_part_1']:");
            console.log(errors.value['personal_number_part_1'][0]);            
        }

        // this works for the nested attribute objects
        // personal_number.part_1
        if(errors.value['personal_number.part_1']) {
            console.log("errors.value.personal_number.part_1[0]:");
            console.log(errors.value['personal_number.part_1'][0]);
        }

        if(errors.value['personal_number_merged']) {
            console.log("errors.value.personal_number_merged[0]:");
            console.log(errors.value['personal_number_merged'][0]);
        }
    }

    console.log("App received following errors:");
    for (const [key, value] of Object.entries(errors.value)) {
        console.log("Received error:");
        console.log(`${key}: ${value}`);
        errorMessages.push(`${value}`);
    }
    
    return Object.keys(errors.value).length > 0;
});

const errorMap = {
    'address.country': "Země původu",
    'address.city': "Město",
    'address.street': "Ulice",
    'address.house_number': "Číslo popisné",
    'address.orientation_number': "Číslo orientační",
    'address.postal_code': "Poštovní směrovací číslo",
    'address_secondary.country': "Země původu u kontaktní adresy",
    'address_secondary.countryCode': "Země původu u kontaktní adresy",
    'address_secondary.city': "Město u kontaktní adresy",
    'address_secondary.street': "Ulice u kontaktní adresy",
    'address_secondary.house_number': "Číslo popisné u kontaktní adresy",
    'address_secondary.orientation_number': "Číslo orientační u kontaktní adresy",
    'address_secondary.postal_code': "Poštovní směrovací číslo u kontaktní adresy",
    // Add more mappings as needed
};
const onAdvancedUpload = (event) => {
    console.log("object:");
    console.log(object);
    console.log("file uploaded");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};

const onSelectedFiles = (event) => {
    console.log("event:");
    console.log(event);
    console.log("file selected");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    fileWasSelected.value = true;
}

const onUploadError = (event) => {
    console.log("event:");
    console.log(event);
    console.log("file upload error");
    //toast.add({ severity: 'error', summary: 'Error', detail: 'File Upload Error', life: 3000 });
}


async function customUpload(event, uploadType) {

    console.log("event:");
    console.log(event);
    console.log("custom file uploaded");
    
    uploadedFile = event.files[0];

    console.log("uploadedFile:");
    console.log(uploadedFile);
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    // Create a new div element for the custom message
    const customMessage = document.createElement('div');
    customMessage.innerHTML = '<p style="color: green;">Soubor úspěšně nahrán.</p>'; // Replace with your actual message
    customMessage.classList.add('custom-message-class'); // Add any classes you need for styling

    // The upload itself...
    let formFileData = new FormData();
        
    console.log("responseData:");
    console.log(responseData);
    // let user_id = responseData['user']['id'];

    formFileData.append('image', uploadedFile);

    let user_id = user.value.id;
    console.log("User ID: " + user_id);

    formFileData.append('user_id', user_id);
    formFileData.append('file', uploadedFile);

    if(uploadType === "document") {
        console.log("selectedDocumentType.value:");
        console.log(selectedDocumentType.value);

        if(!selectedDocumentType.value) {
            fileWasUploaded.value = false;
            fileWasUploadedError.value = true;
            let buttonBar = document.querySelector('#document-upload-section');
            buttonBar.append("Chyba při nahrání souboru. Musíte vybrat typ souboru. Nahrejte soubor znovu a vyberte typ.");
        } else {
            formFileData.append('document_type_id', selectedDocumentType.value);
        }
    }
    
    await axiosClient.get('/sanctum/csrf-cookie')
    .catch(error => {
        console.log("error in requesting csrf-cookie");
        console.log(error);
        programError.value = true;
        userEditSuccesful.value = false;
    });
    console.log("File was uploaded. Trying to store it.");

    let uploadUrl = '/admin/user/profile/img-upload';

    if(uploadType === "document") {
        uploadUrl = '/employee-management/create/file-upload';
    }

    
    await axiosClient.post(uploadUrl, formFileData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    })  
    .then(response => {
        console.log("response: /admin/user/profile/img-upload");
        console.log(response);
        
        let buttonBar = null;
        if(uploadType === "document") {
            console.log("response.data:");
            console.log(response.data);
            user.value.employer_documents.push(response.data);
            console.log("buttonBar:");
            console.log(buttonBar);
            buttonBar = document.querySelector('#document-upload-section');
        } else if (uploadType === "img") {
            user.img_path = response.data.img_path;
            dynamicUserData.img_path = response.data.img_path;   
            buttonBar = document.querySelector('#img-upload-section');
        } else {
            console.error("Unknown upload type: " + uploadType);
        }

        // buttonBar.closest('.p-fileupload-buttonbar');
        //console.log("buttonBar:");
        //console.log(buttonBar);

        console.log("user.img_path:");
        console.log(user.img_path);

        imgUploaded.value = true;

        fileWasUploaded.value = true;
        fileWasUploadedError.value = false;
        
        // Create a new div element for the custom message
        const customMessage = document.createElement('div');
        customMessage.innerHTML = '<p style="color: green;">Soubor úspěšně nahrán.</p>'; // Replace with your actual message
        customMessage.classList.add('custom-message-class'); // Add any classes you need for styling
        console.log("File upload succesful");

        // Now insert the custom message into the DOM, before the contentArea
        if(uploadType === "img" || uploadType === "document") {
            buttonBar.append("Nahrání bylo úspěšné.");
            reloadData();    
        } else {
            console.error("Unknown upload type: " + uploadType);
        }
    })
    .catch(error => {
        if(error.response) {
            if (error.response.status === 422) {
                console.log(error.response.data.errors);
                errors.value = error.response.data.errors;
            } else {
                programError.value = true;
                console.log("Other error:");
                console.log(error);
            }   
            // TODO: Add separate error for the image upload. PRIORITY: A

        } else {
            console.log("Error: " + error);
            // programError.value = true;
        }
        userEditSuccesful.value = false;
        fileUploadError.value = true;
    });

    // Reset the form and clear the uploaded file.
    fileUpload.value.clear();
    fileUpload.value.uploadedFileCount = 0;
}

const deleteEmployee = async (employeeData) => {
  if (confirm(`Are you sure you want to delete ${employeeData.firstname} ${employeeData.lastname}?`)) {
    try {
      const response = await EmployeeService.deleteEmployee(employeeData.id);
      if (response.status === 200) {
        toast.add({ severity: 'success', summary: 'Success', detail: 'Zaměstnanec úspěšně smazán.', life: 3000 });
        reloadData();
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
      console.log(error);
      toast.add({ severity: 'error', summary: 'Error', detail: 'Chyba při smazání uživatele.', life: 3000 });
    }
  }
};

const editEmployee = (employeeData) => {
  console.log('editEmployee called with:', employeeData);
  user.value = { ...employeeData };
  employeeDialog.value = true;

  // Handle personal number
  user.value.personal_number = {
    part_1: employeeData.personal_number_part_1 || '',
    part_2: employeeData.personal_number_part_2 || ''
  };

  // Handle primary address
  if (employeeData.addresses_primary && employeeData.addresses_primary.length > 0) {
    user.value.address = { ...employeeData.addresses_primary[0] };
  } else {
    user.value.address = {
      city: '',
      street: '',
      postal_code: '',
      house_number: '',
      orientation_number: '',
      country: ''
    };
  }

  // Handle secondary address
  if (employeeData.addresses_secondary && employeeData.addresses_secondary.length > 0) {
    user.value.address_secondary = { ...employeeData.addresses_secondary[0] };
  } else {
    user.value.address_secondary = {
      city: '',
      street: '',
      postal_code: '',
      house_number: '',
      orientation_number: '',
      country: ''
    };
  }

  // Handle bank account
  if (employeeData.bank_account && employeeData.bank_account.length > 0) {
    user.value.bank_account = { ...employeeData.bank_account[0] };
  } else {
    user.value.bank_account = {
      prefix: '',
      account_number: '',
      bank_number: '',
      allow_sending_salary: false
    };
  }

  errors.value = {};
};


const hideDialog = () => {
    employeeDialog.value = false;
    submitted.value = false;
};

const formatErrorKey = (key) => {
    // Transform the key to match the error message format:
    // e.g., 'address.country' to 'Address.country'
    // and 'address_secondary.country' to 'Address secondary.country'
    return key.split('.').map((part, index) => {
        if (index === 0 && part === 'address') {
            return 'Address'; // Capitalize 'address'
        } else if (part === 'secondary') {
            return ' secondary'; // Add a space before 'secondary'
        } else {
            return part.replace(/_/g, ' '); // Replace underscores with spaces
        }
    }).join('.');
};


const hasAddressError = computed(() => {

    // debug
    console.log("hasAnyError:");
    console.log(errors.value);

    if(errors.value) {
        for (const [key, humanReadableKey] of Object.entries(errorMap)) {
            if (errors.value[key]) {
                errors.value[key].forEach((error) => {
                    const formattedKey = formatErrorKey(key);
                    // Use a case-insensitive regular expression for replacement
                    const messageWithoutFieldName = error.replace(new RegExp(formattedKey, 'i'), humanReadableKey);
                    errorMessages.push(messageWithoutFieldName);
                });
            }
        }

        return errorMessages;
    }

    return false;
});

const hasBankAccountError = computed(() => {

// debug
console.log("hasAnyError:");
console.log(errors.value);

if(errors.value) {
    for (const [key, humanReadableKey] of Object.entries(errorMap)) {
        if (errors.value[key]) {
            errors.value[key].forEach((error) => {
                const formattedKey = formatErrorKey(key);
                // Use a case-insensitive regular expression for replacement
                const messageWithoutFieldName = error.replace(new RegExp(formattedKey, 'i'), humanReadableKey);
                errorMessages.push(messageWithoutFieldName);
            });
        }
    }

    return errorMessages;
}

return false;
});


const hasPhoneError = computed(() => {
if(errors.value?.phone?.[0]) {
    return "Telefonní číslo je ve špatném formátu.";
}
return false;
});

const hasBirthDateError = computed(() => {
if(errors.value?.birth_date?.[0]) {
    return "Datum narození je ve špatném formátu.";
}
return false;
});

const hasBirthPlaceError = computed(() => {
if(errors.value?.birth_place?.[0]) {
    return "Místo narození je ve špatném formátu.";
}
return false;
});

const hasHealthInsuranceCompanyError = computed(() => {
if(errors.value?.health_insurance_company?.[0]) {
    return "Zdravotní pojišťovna je ve špatném formátu.";
}
return false;
});

const birthPlaceError = computed(() => {
if(errors.value?.birth_place?.[0]) {
    return "Místo narození je špatně zadáno.";
}
return false;
});

const hasPersonalNumberError = computed(() => {
if(errors.value) {
    console.log("hasPersonalNumberError errors.value"); // TODO
    console.log(errors.value);
    if (errors.value['personal_number.part_1'] || errors.value['personal_number.part_2'] || errors.value['personal_number_merged']) {
        return "Rodné číslo je ve špatném formátu.";
    }
}

return false;
});

const hasFileUploadError = computed(() => {
    if(fileUploadError.value === true) {
        console.log("fileUploadError.value"); // TODO
        console.log(fileUploadError.value);
        return "Chyba při nahrání souboru.";
    }

    return false;
});

</script>

<template>
  <header class="bg-white shadow">
      <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold tracking-tight text-gray-900">Správa zaměstnanců</h1>
      </div>
  </header>
  <main>
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <!-- Edit employee style -->
        <Toast />
          
        <div class="p-2 md:p-4">
            <Dialog v-model:visible="employeeDialog" :style="{width: '70%'}" :modal="true" class="p-fluid">
              <form class="space-y-6" action="#" @submit.prevent="saveEmployee">
                <div class="card">
                    <div class="mb-2 sm:mb-6">
                        <h1 data-cy="employee-edit-headline" id="employee-edit-headline" class="text-3xl font-bold tracking-tight text-gray-900">Úpravy zaměstnance</h1>
                        <h2 data-cy="file-upload-headline" id="file-upload-headline" class="pl-6 text-2xl font-bold sm:text-xl">Soubory zaměstnance</h2>
                        <div>
                            <ul>
                                <li v-for="(document, index) in user.employer_documents" :key="index">
                                    <a :href="document.url" target="_blank">Dokument {{ index + 1 }}: {{ document.original_name }}</a>
                                    - <b> {{ document.document_type.type_name  }} - {{ document.document_type.type_description }} </b> - {{ document.created_at }}
                                    <Button icon="pi pi-trash" outlined rounded severity="danger" @click="deleteDocument(document, index)" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2">
                        <h2>Typ (štítek) souboru</h2>
                        <select v-model="selectedDocumentType" name="document_type_id">
                            <option value="" disabled>Vyberte typ dokumentu</option>
                            <option v-for="docType in documentTypes" :key="docType.id" :value="docType.id">
                                {{ docType.type_name }}
                            </option>
                        </select>
                    </div>
                    <Toast />
                    <div id="document-upload-section">
                        <FileUpload
                            @upload="onAdvancedUpload($event)" 
                            :multiple="false" 
                            name="demo[]"
                            aria-labelledby="fileUploadLabel"
                            accept=".pdf" 
                            :maxFileSize="10000000" 
                            cancelLabel="Zrušit" 
                            chooseLabel="Vybrat" 
                            uploadLabel="Nahrát"
                            invalidFileSizeMessage="Velikost souboru je měl být než 10 MB."
                            customUpload 
                            @uploader="(event) => customUpload(event, 'document')"
                            @select="onSelectedFiles($event)"
                        >
                            <template #empty>
                                <p>Přetáhni sem soubor pro nahrání. Povoleno je PDF.</p>
                            </template>
                        </FileUpload>
                        <ul v-if="hasFileUploadError" class="text-red-600">
                            Nahrání souboru obsahuje chyby:
                            {{ hasFileUploadError }}
                        </ul>
                    </div>
                    <p id="file-upload-instruction">Po vybrání souboru vyberte <b>Typ souboru</b> a klikněte na <b>Nahrát</b>.</p>
                </div>
                
                <div class="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
                  <img class="mb-3 object-cover w-40 h-40 p-1 rounded-md ring-2 ring-indigo-300 dark:ring-indigo-500"
                      :src="user.profile_photo_url"
                      alt="Profilový obrázek">
                </div>  

                <div>
                    <BaseInput
                        label="Jméno (Name)*"
                        name="firstname"
                        v-model="user.firstname"
                        placeholder="Jméno (Name)"
                        required
                    />
                    <span class="text-red-600" v-if="errors?.firstname">{{ errors.firstname[0] }}</span>
                </div>

                <div>
                    <BaseInput
                    label="Příjmení (Last Name)"
                    name="lastname"
                    v-model="user.lastname"
                    placeholder="Příjmení (Last Name)"
                    required
                    />
                    <span class="text-red-600" v-if="errors?.lastname">{{ errors.lastname[0] }}</span>
                </div>
                <div>
                    <BaseInput
                    label="E-mailová adresa*"
                    name="email"
                    type="email"
                    v-model="user.email"
                    autocomplete="email"
                    required
                    />
                    <span class="text-red-600" v-if="errors?.email">{{ errors.email[0] }}</span>

                    <div class="field field">
                      <label for="personal_number"
                            class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Osobní číslo</label>
                      <InputText name="id" type="text" id="personal_number" v-model="user.id"
                            placeholder="Osobní číslo zaměstnance" disabled />
                    </div>

                </div>

                <ul v-if="hasAddressError.length" class="text-red-600">
                    <li v-for="(error, index) in hasAddressError" :key="index">
                        {{ error }}
                    </li>
                </ul>
                
                <section id="primary-address">
                    <h2>Adresa</h2>
                    <ul v-if="hasAddressError.length" class="text-red-600">
                        <li v-for="(error, index) in hasAddressError" :key="index">
                            {{ error }}
                        </li>
                    </ul>

                    <div>
                        <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Národnost</label>
                        <div class="field">
                            <InputText name="country" type="text" id="country" v-model="user.address.country"
                                placeholder="Kód země" />
                        </div>
                    </div>
                    <AddressComponent :user_address="user.address" suffix="primary" :isRequired="true"></AddressComponent>
                </section>
                
                <!-- TODO: Roll when the secondary address exists for the employee. -->
                <Accordion>
                    <AccordionTab header="Kontaktní adresa (nepovinné)">
                        <section id="secondary-address-block">
                            <div>
                                <label for="country_code_secondary" class="block text-sm font-medium leading-6 text-gray-900">Kód země</label>
                                <div class="mt-2">
                                    <input id="country_code_secondary" name="country_code_secondary" type="text" autocomplete="country_code_secondary"
                                        placeholder="Kód země"
                                        v-model="user.address_secondary.country"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                                    ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                    focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                            <AddressComponent :user_address="user.address_secondary" suffix="secondary" :isRequired="false"></AddressComponent>
                        </section>
                    </AccordionTab>
                </Accordion>

                <h2>Kontakt</h2>
                <div v-if="hasPhoneError" class="text-red-600">
                    Telefoní číslo není zadáno v platném formátu.
                </div>
                
                <div class="block">
                    <BaseInput
                        label="Telefonní číslo*"
                        name="phone_prefix"
                        v-model="user.phone_prefix"
                        :value="user.phone_prefix"
                        inputClass="w-20 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 inline"
                        divClass="inline"
                        placeholder="420"
                        pattern="[0-9]{3}"
                        maxlength="3"
                        required
                    />
                    <InputMask
                        id="phone_number"
                        label="Telefonní číslo*"
                        name="phone_number"
                        v-model="user.phone_number"
                        :value="user.phone_number"
                        mask="999 999 999"
                        placeholder="999 999 999"
                        pattern="[0-9]{3} [0-9]{3} [0-9]{3}"
                        class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 inline"
                        required
                    />
                </div>
                <small class="block">Zadejte ve tvaru: 420 111 222 333 (s mezerami)</small>
                
                <h2>Osobní údaje</h2>
                
                <div v-if="hasBirthDateError" class="text-red-600">{{ hasBirthDateError }}</div>
                <div>
                    <BaseInput
                        label="Datum narození*"
                        name="birth_date"
                        type="date"
                        v-model="user.birth_date"
                        placeholder="Datum narození"
                        required
                    />
                </div>

                <div v-if="hasHealthInsuranceCompanyError" class="text-red-600">{{ hasHealthInsuranceCompanyError }}</div>
                <div>
                    <BaseInput
                        label="Zdravotní pojišťovna (vypište název)*"
                        name="health_insurance_company"
                        type="text"
                        v-model="user.health_insurance_company"
                        placeholder="Zdravotní pojišťovna"
                        required
                    />
                </div>

                <div v-if="hasBirthPlaceError" class="text-red-600">{{ hasBirthPlaceError }}</div>
                <div>
                    <BaseInput
                        label="Místo narození"
                        name="birth_place"
                        type="text"
                        v-model="user.birth_place"
                        placeholder="Místo narození"
                        required
                    />
                </div>

                <div v-if="hasPersonalNumberError" class="text-red-600">{{ hasPersonalNumberError }}</div>
                <div>
                    <label for="personal_number" class="block text-sm font-medium leading-6 text-gray-900">
                        Rodné číslo*
                    </label>
                    <div class="flex">
                        <BaseInput
                            name="personal_number_part_1"
                            type="text"
                            v-model="user.personal_number_part_1"
                            placeholder="Rodné číslo: 1. část"
                            pattern="[0-9]{6}"
                            minlength="4"
                            maxlength="6"
                            inputClass="personal_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                        />
                        <span class="slash-between-inputs">/</span>
                        <BaseInput
                            name="personal_number_part_2"
                            type="text"
                            v-model="user.personal_number_part_2"
                            placeholder="Koncovka"
                            pattern="\d{3}|\d{4}"
                            minlength="3"
                            maxlength="4"
                            inputClass="personal_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                    <small>Zadejte pouze číslovky, bez lomítka.</small>
                </div>

                <div>
                    <label for="bank_account" class="block text-sm font-medium leading-6 text-gray-900">
                        Bankovní číslo
                    </label>
                    <div class="flex">
                        <BaseInput
                        name="prefix"
                        type="text"
                        v-model="user.bank_account.prefix"
                        placeholder="Předčíslí"
                        pattern="[0-9]+"
                        minlength="2"
                        maxlength="6"
                        inputClass="bank_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :required="isBankAccountRequired"
                        />
                        <span class="slash-between-inputs">/</span>
                        <BaseInput
                        name="account_number"
                        type="text"
                        v-model="user.bank_account.account_number"
                        placeholder="Číslo účtu"
                        pattern="[0-9]+"
                        maxlength="10"
                        inputClass="bank_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :required="isBankAccountRequired"
                        />
                        <span class="slash-between-inputs">/</span>
                        <BaseInput
                        name="bank_number"
                        type="text"
                        v-model="user.bank_account.bank_number"
                        placeholder="Číslo banky"
                        pattern="[0-9]{4}"
                        maxlength="4"
                        minlength="4"
                        inputClass="bank_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :required="isBankAccountRequired"
                        />
                    </div>

                    <ul v-if="hasBankAccountError.length" class="text-red-600">
                        <li v-for="(error, index) in hasBankAccountError" :key="index">
                            {{ error }}
                        </li>
                    </ul>
                    <div class="flex items-center mb-4">
                        <Checkbox 
                            name="allow_sending_salary" 
                            v-model="allowSendingSalary" 
                            id="allow_sending_salary" 
                            :binary="true"
                            disabled
                        />
                        <label for="allow_sending_salary" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Zaměstnanec chce zasílat výplatu na účet
                        </label>
                    </div>
                    <ul v-if="errors.bank_account" class="text-red-600">
                        <li v-for="(error, index) in errors.bank_account" :key="index">
                            {{ error }}
                        </li>
                    </ul>
                    <small>Zadejte pouze číslovky, bez lomítka.</small>
                </div>

                <div id="img-upload-section">
                    <label for="personal_number" class="block text-sm font-medium leading-6 text-gray-900">
                        Nahrejte fotografii
                    </label>
                    <div class="mt-2">
                        <div class="card">
                            <Toast />
                            <FileUpload
                                ref="fileUpload"
                                @upload="onAdvancedUpload($event)"
                                :multiple="false"
                                name="demo[]"
                                accept="image/*"
                                :maxFileSize="10000000"
                                cancelLabel="Zrušit"
                                chooseLabel="Vybrat"
                                uploadLabel="Nahrát"
                                invalidFileSizeMessage="Velikost souboru nesmí být větší než 10 MB."
                                invalidFileTypeMessage="Špatný formát souboru. Soubor musí být obrázek formátu: jpg, jpeg, png nebo tif."
                                customUpload
                                @uploader="(event) => customUpload(event, 'img')"
                                fileLimit="1"
                                @select="onSelectedFiles($event)"
                                @error="onUploadError($event)"
                                data-cy="img-upload"
                                >
                                <template #empty>
                                    <p>Přetáhni sem soubor pro nahrání. Povoleny jsou typické soubory obrázků (např. png, jpeg).</p>
                                </template>
                            </FileUpload>
                            <p id="img-upload-instruction">Po vybrání souboru klikněte na <b>Nahrát</b>.</p>
                        </div>
                    </div>
                    <ul v-if="hasFileUploadError" class="text-red-600">
                        Nahrání souboru obsahuje chyby:
                        {{ hasFileUploadError }}
                    </ul>
                </div>

                <div v-if="authError" class="error-message" style="color: red">
                    {{ authError }}
                </div>

                <div>
                  <div>
                    <Button label="Zrušit" icon="pi pi-times" text @click="hideDialog"/>
                    <BaseBtn
                        data-cy="register-submit-button"
                        type="submit"
                        text="Uložit"
                    />                  
                  </div>
                  <div id="program-error" v-if="programError" class="error-message" style="color: red">
                        Systém je dočasně nedostupný, vyzkoušejte prosím později.
                    </div>
                    <div data-cy="has-any-error" v-if="hasAnyError" class="text-red-600">
                        Prosím, opravte chyby ve formuláři.
                    </div>
                    <div id="file-upload-error" v-if="fileWasUploadedError" class="error-message" style="color: red">
                        Nahrejte prosím fotografii stiskem na tlačítko "Nahrát".
                    </div>
                    <div id="success" v-if="success" class="text-green-600">
                        Odeslání formuláře bylo úspěšné.
                    </div>
                </div>

              </form>


            </Dialog>
        </div>

        <h2 class="pl-6 text-2xl font-bold sm:text-xl">Zaměstnanci</h2>

        <!-- Data table section -->
        <!-- Use, e.g. @click="editemployee(slotProps.data)" -->

        <!-- NOTE: There can be also country code and other stuff, check the official docummentation. -->
        <div class="card">
            <DataTable v-model:filters="filters" :value="users" paginator :rows="10" 
                dataKey="id" filterDisplay="row" :loading="lazyLoading" :lazy="true"
                :totalRecords="totalRecords" @page="onPage" @sort="onSort" @filter="onFilter"
                :globalFilterFields="['lastname', 'email', 'created_at', 'status', 'birth_date', 'birth_place', 'health_insurance_company']"
            >
                <template #header>
                    <div class="flex justify-content-end">
                        <IconField iconPosition="left">
                            <InputText v-model="filters['global'].value" @input="onFilter" placeholder="Hledat dle jména nebo e-mailu..." />
                        </IconField>
                    </div>
                </template>
                <template #empty> Načítání informací o zaměstnancích... </template>
                <template #loading> Načítám informace o zaměstnancích, prosím čekejte. </template>

                <Column field="lastname" header="Jméno" :sortable="true" :filter="true" filterMatchMode="contains" :showFilterMenu="false">                    
                    <template #body="{ data }">
                        {{ data.firstname }} {{ data.lastname }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Dle jména" />
                    </template>
                </Column>

                <Column field="edits" header="Úpravy" style="min-width:8rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" outlined rounded class="mr-2"
                            @click="editEmployee(slotProps.data)"/>
                        <Button icon="pi pi-trash" outlined rounded severity="danger" 
                            @click="deleteEmployee(slotProps.data)"/>
                    </template>
                </Column>
                <Column field="email" header="E-mail" :sortable="true" :filter="true" filterMatchMode="contains" :showFilterMenu="false">
                    <template #body="{ data }">
                        <div class="flex align-items-center gap-2">
                            <span v-if="data.email">{{ data.email}}</span>
                        </div>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Hledat dle e-mailu" />
                    </template>
                </Column>
                <Column field="address_primary" header="Adresa" :sortable="false" :filter="false" filterMatchMode="contains">
                    <template #body="{ data }">
                        <div class="flex align-items-center gap-2">
                            <span v-if="data.addresses_primary && data.addresses_primary.length > 0 && data.addresses_primary[0].address_combined">
                                {{ data.addresses_primary[0].address_combined }}
                            </span>
                            <span v-else>
                                Adresa není k dispozici
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field="attachements.image" header="Fotografie" style="min-width: 12rem">
                    <template #body="{ data }">
                        <div v-if="data.profile_photo_url" class="flex align-items-center gap-2">
                        <a :href="`${data.profile_photo_url}`" target="_blank" class="text-blue-500 hover:text-blue-700">
                            <img class="w-6rem border-round" :alt="data.profile_photo_url" :src="`${data.profile_photo_url}`" style="width: 32px" />
                        </a>                      
                        </div>
                    </template>
                </Column>

                <Column field="document" header="Dokumenty" style="min-width: 12rem">
                    <template #body="{ data }">
                        <ul>
                            <li v-for="(document, index) in data.employer_documents" :key="index">
                                <span>&#8226;</span> 
                                <a v-if="document.url" 
                                    :href="document.url" 
                                    :title="document.original_name || 'Dokument bez názvu'"
                                    target="_blank">
                                    {{ document.document_type.type_name }}: {{ document.original_name || 'Dokument bez názvu' }}
                                </a>
                            </li>
                        </ul>
                    </template>
                </Column>

                <Column field="status" header="Status" :sortable="true" :filter="true" filterMatchMode="equals">                    
                    <template #body="{ data }">    
                        <Tag :value="data.status" :severity="getSeverity(data.status)" />
                    </template>
                </Column>

                <Column field="created_at" header="Datum registrace" style="min-width: 12rem" sortable>
                    <template #body="{ data }">
                        {{ formatDate(data.created_at) }} <!-- Assuming formatDate is a method that formats Date objects -->
                    </template>
                </Column>

                <Column field="birth_date" header="Datum narození" style="min-width: 12rem" sortable>
                    <template #body="{ data }">
                        {{ formatDate(data.birth_date) }} <!-- Assuming formatDate is a method that formats Date objects -->
                    </template>
                </Column>

                <Column field="birth_place" header="Místo narození" :sortable="true" :filter="true" filterMatchMode="contains" :showFilterMenu="false">                    
                    <template #body="{ data }">
                        {{ data.birth_place }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Hledat dle místa narození" />
                    </template>
                </Column>

                <Column field="health_insurance_company" header="Zdravotní pojišťovna" :sortable="true" :filter="true" filterMatchMode="contains" :showFilterMenu="false">                    
                    <template #body="{ data }">
                        {{ data.health_insurance_company }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Hledat dle zdravotní pojišťovny" />
                    </template>
                </Column>

                <Column field="verified" header="Ověření" dataType="boolean" style="min-width: 6rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.email_verified_at, 'pi-times-circle text-red-400': !data.email_verified_at }"></i>
                    </template>
                    <!--  ***HERE WAS a verified button.*** ---->
                </Column>
            </DataTable>
        </div>
        <!-- END Data table section -->
    </div>
  </main>
</template>
