<script setup>
import axiosClient from '@/axios';
import BaseBtn from '@/components/BaseBtn.vue';
import { computed } from 'vue';
import { ref } from 'vue';
import {useCommonUserMessage} from '@/store/commonMsgStore';
import router from '@/router';
import { useRoute } from 'vue-router';

console.log("ForgotAdminPassword.vue");

const email = ref('');
let errors = ref({});
let success = ref('');
const route = useRoute();
let permissionError = ref(false);

const scleroticUser = {
    email: '',   
};

console.log("route.query.email:");
console.log(route.query);

const hasAnyProgramError = ref(false);
const commonUsrMessageStore = useCommonUserMessage();

console.log("store:");
console.log(commonUsrMessageStore.getProgramErrorMsg);

async function resetPassword(email) {

    await axiosClient.get('/get-permissions')
        .then((response) => {
            console.log("response:");
            console.log(response);
            if(response.data.length === 0) {
                userRole.value = "user";
            } else {
                userRole.value = response.data;
            }
        }).catch((error) => {
            console.log("Error in get-permissions");
            console.log(error);
        }
    );
    console.log("userRole:");
    console.log(userRole.value);
    
    let resetPasswordLink = '';
    if(userRole) {
        console.log("userRole inside watch:");
        console.log(userRole.value.data);
        if (userRole.value.includes('see_admin_sections') || userRole.value.includes('see_superadmin_sections')) {
            console.log("userRole.value === 'see_admin_sections':");
            isAdmin.value = true;
            resetPasswordLink = '/reset-admin-password';
        } else {
            console.log("userRole.value !== 'see_admin_sections':");
            isAdmin.value = false;
            resetPasswordLink = '/reset-password';
        }
    }
    
    console.log("Entered e-mail: " + email);

    await axiosClient.get('/sanctum/csrf-cookie').catch(error => {
        console.log("error while getting csrf-cookie: " + error);
        hasAnyProgramError.value = true;
    });
    await axiosClient.post('/admin/is-admin-email', scleroticUser)
    .then(response => {
        console.log("admin e-mail matches.");
        permissionError.value = false;
    })
    .catch((errors) => {
        console.log("errors:");
        console.log(errors);
        permissionError.value = true;
    });

    if(permissionError.value === false) {
        await axiosClient.get('/sanctum/csrf-cookie').catch(error => {
            console.log("error while getting csrf-cookie: " + error);
            hasAnyProgramError.value = true;
        });
        await axiosClient
        .post('/forgot-password', scleroticUser)
        .then(response => {
            success.value = response.data.message; // TODO: Display on the login page the success message. PRIORITY: C
            console.log("success.value:");
            console.log(success.value);
            router.push({name: 'login'});
        })
        .catch(error => {
            if(error.response) {
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                    hasAnyProgramError.value = false;
                } else {
                    hasAnyProgramError.value = true;
                }
            } else {
                console.log("Other error:");
                console.log(error);
                hasAnyProgramError.value = true;
            }

            throw new Error(error);
        });
    }
}

const hasAnyError = computed(() => {
    console.log("hasAnyError:");
    console.log(errors.value);

    return Object.keys(errors.value).length > 0;
});

const errorMsg = computed(() => {
    console.log("hasAnyError:");
    console.log(errors.value);

    let errorMsgList = [];

    // Loop over each key in the errors object
    Object.keys(errors.value).forEach((key) => {
        // Concatenate all messages from the array corresponding to the key
        errorMsgList = errorMsgList.concat(errors.value[key]);
    });

    // errorMsgList = errorMsgList.length == 1 ? errorMsgList[0] : [ errorMsgList.slice(0, errorMsgList.length - 1).join(", "), errorMsgList[errorMsgList.length - 1] ].join(" and ");

    return errorMsgList.join(' ');
});

</script>

<template>
    <section class="bg-gray-50 dark:bg-gray-900" style="overflow: hidden">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img class="h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
                4events    
            </a>
            <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Změna hesla
                </h2>
                <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#" @submit.prevent="resetPassword(email)">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Současný administrátorský e-mail</label>
                        <input v-model="scleroticUser.email" type="email" name="email" id="email" placeholder="admin@domena.cz" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                    </div>
                    <BaseBtn type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset password</BaseBtn>
                    <div v-if="hasAnyError" style="color:red">{{ errorMsg }}</div>
                    <div v-if="hasAnyProgramError" style="color: red;">{{ commonUsrMessageStore.getProgramErrorMsg }}</div>
                    <div v-if="permissionError" style="color: red;">Nemáte oprávnění k této akci.</div>
                    <div v-if="noAdminEmailError" style="color: red;">Tento e-mail není administrátorský.</div>
                </form>
            </div>
        </div>
  </section>
</template>
