<script setup>
import { defineProps, computed } from 'vue';
import BaseInput from './BaseInput.vue';
import {onMounted} from 'vue';

// Accepting an additional prop for unique identification
const props = defineProps({
  user_address: {
    type: Object,
  },
  suffix: {
    type: String,
    default: ''
  },
  isRequired: {
    type: Boolean,
    default: false
  }
});

const selectCountryOptions = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'}
];

const computedCountryCode = computed(() => props.user_address.countryCode);

// console.log("AddressComponent");
function setInputFilter(textbox, inputFilter, errMsg) {
  [ "input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout" ].forEach(function(event) {
    textbox.addEventListener(event, function(e) {
      if (inputFilter(this.value)) {
        // Accepted value.
        if ([ "keydown", "mousedown", "focusout" ].indexOf(e.type) >= 0) {
          this.classList.remove("input-error");
          this.setCustomValidity("");
        }
        
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      }
      else if (this.hasOwnProperty("oldValue")) {
        // Rejected value: restore the previous one.
        this.classList.add("input-error");
        this.setCustomValidity(errMsg);
        this.reportValidity();
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
      else {
        // Rejected value: nothing to restore.
        this.value = "";
      }
    });
  });
}

onMounted(() => {
  setInputFilter(document.querySelector("input[id='postal_code_primary']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 99999);
  }, "Zadejte prosím platnou PSČ");

  setInputFilter(document.querySelector("input[id='postal_code_secondary']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 99999);
  }, "Zadejte prosím platnou PSČ");

  setInputFilter(document.querySelector("input[id='personal_number_part_1']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 999999);
  }, "Zadejte prosím platnou první část rodného čísla.");

  setInputFilter(document.querySelector("input[id='personal_number_part_2']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 9999);
  }, "Zadejte prosím platnou druhou část rodného čísla.");

  setInputFilter(document.querySelector("input[id='prefix']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 999999);
  }, "Zadejte prosím platné předčíslí.");

  setInputFilter(document.querySelector("input[id='account_number']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 9999999999);
  }, "Zadejte prosím platné číslo účtu.");

  setInputFilter(document.querySelector("input[id='bank_number']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 9999);
  }, "Zadejte prosím platné číslo banky.");
});

</script>

<template>
    <div>
        <BaseInput
            :label="`Město${props.isRequired ? '*' : ''}`"
            :name="`city_${props.suffix}`"
            v-model="props.user_address.city"
            placeholder="Město"
            :required="props.isRequired"
        />
    </div>

    <div>
        <BaseInput
            :label="`PSČ${props.isRequired ? '*' : ''}`"
            :name="`postal_code_${props.suffix}`"
            v-model="props.user_address.postal_code"
            placeholder="PSČ"
            pattern="[0-9]{5}"
            :required="props.isRequired"
            :maxlength="5"
            :minlength="5"
        />
        <small>Zadejte bez mezery</small>
    </div>

    <div>
        <BaseInput
            :label="`Ulice${props.isRequired ? '*' : ''}`"
            :name="`street_${props.suffix}`"
            v-model="props.user_address.street"
            placeholder="Ulice"
            :required="props.isRequired"
        />
    </div>

    <div>
        <BaseInput
            :label="`Číslo popisné${props.isRequired ? '*' : ''}`"
            :name="`house_number_${props.suffix}`"
            v-model="props.user_address.house_number"
            placeholder="Číslo popisné"
            pattern="[A-Z0-9]+"
            :required="props.isRequired"
        />
    </div>
    <div>
        <BaseInput
            :label="`Číslo orientační`"
            :name="`orientation_number_${props.suffix}`"
            v-model="props.user_address.orientation_number"
            placeholder="Číslo orientační"
            pattern="[a-zA-Z0-9]+"
            :required=false
        />
    </div>
</template>
