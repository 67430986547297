<script>
import DataTable from 'primevue/datatable';
import Calendar from 'primevue/calendar';

  const eventTypes = ref(  
  [
    /*
        { name: 'Ples', code: 'ples' },
        { name: 'Festival', code: 'festival' },
        { name: 'Obecní slavnost', code: 'obecni_slavnost' },
    */
   ]
  );
  const relevantCountries = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'} 
  ];
  export default {
    name: 'myEvents',

    data() {
      console.log("selectEventTypes:", eventTypes);
      return {
        pageTitle: 'Moje směny',
        selectEventTypes: eventTypes,
        selectCountryOptions: relevantCountries,
        event: {
          name: '',
          date: '',
          time: '',
          salary: '',
          type:  '',
          address: {country: '', countryCode: '', city: '', street: '', postal_code: '', house_number: '', orientation_number: ''},
        },
        gps: '',
        error: null
      };
    }
  }

</script>

<template>
<header class="bg-white shadow">
  <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900">{{ pageTitle }}</h1>
  </div>
</header>
<main>
  <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
    <div class="p-2 md:p-4">
      <h2 class="pl-6 text-2xl font-bold sm:text-xl">Akce</h2>

      <!-- Data table section -->
      <!-- Use, e.g. @click="editProduct(slotProps.data)" -->

      <!-- NOTE: There can be also country code and other stuff, check the official docummentation. -->
      <div class="card">
          <DataTable v-model:filters="filters" :value="events" paginator :rows="10" dataKey="id" 
          filterDisplay="menu" :loading="loading"
                  :globalFilterFields="['name', 'type', 'address']">
              <template #header>
                <div class="flex justify-content-between">
                    <Button type="button" icon="pi pi-filter-slash" label="Vymazat filtr" outlined @click="clearFilter()" />
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Hledat klíčová slova..." />
                    </IconField>
                </div>
              </template>
              <template #empty> Nebyly nalezeny žádné směny. </template>
              <template #loading> Načítám data o akcích. Prosíme o strpení. </template>

              <Column field="name" header="Název akce" style="min-width: 12rem" sortable>
                  <template #body="{ data }">
                      {{ data.name }}
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Hledat dle jména" />
                  </template>
              </Column>

              <Column field="type" header="Typ akce" style="min-width: 12rem" sortable>
              <template #body="{ data }">
                  {{ data.type }} <!-- Treat type as a string -->
              </template>
              <template #filter="{ filterModel, filterCallback }">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Hledat dle typu akce" />
              </template>
            </Column>


              <Column field="address" header="Adresa" filterField="address" style="min-width: 12rem">
                  <template #body="{ data }">
                      <div class="flex align-items-center gap-2">
                          <span>{{ data.address.street }}, {{ data.address.house_number }}, {{ data.address.orientation_number }}, {{ data.address.city }}, {{ data.address.postal_code }}</span>
                      </div>
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country" />
                  </template>
              </Column>


              <Column field="status" header="Status" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
                  <template #body="{ data }">
                      <Tag :value="data.status" :severity="getSeverity(data.status)" />
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                          <template #option="slotProps">
                              <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                          </template>
                      </Dropdown>
                  </template>
              </Column>
              
              <Column header="Datum" filterField="date" dataType="date" style="min-width: 10rem">                
                <template #body="{ data }">
                    {{ formatDate(data.date) }} 
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <Calendar v-model="filterModel.value" @change="filterCallback()" dateFormat="dd. mm. yy" placeholder="dd. mm. yyyy" mask="99. 99. 9999" />
                </template>
              </Column>

              <Column field="time" header="Čas" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    {{data.time }}
                </template>
              </Column>

              <Column field="edits" header="Úpravy" style="min-width:8rem">
                  <template #body="slotProps">
                      <Button label="Přijmout" class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4
                        focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm w-full
                        sm:w-auto px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700
                        dark:focus:ring-primary-800 m-2" />
                      <Button label="Odmítnout" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4
                      focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full
                      sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700
                      dark:focus:ring-red-800 m-2" />
                  </template>
              </Column>
          </DataTable>
      </div>
    </div>
    <!-- END Data table section -->
  </div>
</main>

</template>

<script setup>

import {onMounted, onUnmounted, watch, ref} from "vue";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { EventService } from '@/services/EventService';
import Dropdown from 'primevue/dropdown';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Button from 'primevue/button';

const selectedEvent = ref();
const events = ref();
const filters = ref();

const statuses = ref(['zrušená', 'proběhlá', 'nová']);
const loading = ref(true);

onMounted(() => {
  EventService.getEventsMedium().then((data) => {
    events.value = getEvents(data); // Adjust this if you need to call a different method
    loading.value = false;
  });
});

const getEvents = (data) => {
  return [...(data || [])].map((d) => {
    d.date = new Date(d.date);

    return d;
  });
};

const initFilters = () => {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'address': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      attachements: { value: null, matchMode: FilterMatchMode.IN },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
      date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      type: { value: null, matchMode: FilterMatchMode.STARTS_WITH }, // Add this line
    };
};

initFilters();

const formatDate = (value) => {
    if (!value) {
        // Handle the case where the value is null, undefined or an empty string
        return '';
    }
    const date = new Date(value);
    if (isNaN(date)) {
        // Handle the case where the value is not a valid date string
        return 'Invalid Date';
    }
    return date.toLocaleDateString('cs-CZ', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};

const getSeverity = (status) => {
  switch (status) {
    case 'zrušená':
        return 'danger';

    case 'proběhlá':
        return 'success';

    case 'nová':
        return 'info';
  }
}

</script>