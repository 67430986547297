<template>
    <div class="max-w-lg p-5 m-auto">
      <article class="p-5">
        <h2 style="color:red"
          class="mb-2 text-6xl font-bold text-transparent bg-gradient-to-r bg-clip-text from-blue-500 to-green-500"
        >
        Na tuto stránku nemáte oprávnění vstoupit.
        </h2>
      </article>
    </div>
  </template>