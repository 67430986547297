export const EventService = {
    getData() {
        return [
          /* Here were a sample data of events. */          
          // ... (other events, if needed, with the corresponding types assigned)
        ];
    },
    getEventsSmall() {
        return Promise.resolve(this.getData().slice(0, 10));
    },

    getEventsMedium() {
        return Promise.resolve(this.getData().slice(0, 50));
    },

    getEventsLarge() {
        return Promise.resolve(this.getData().slice(0, 200));
    },

    getEventsXLarge() {
        return Promise.resolve(this.getData());
    },
};
