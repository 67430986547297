import axios from "axios";
import router from "./router/index.js";
import { useGlobalErrorStore } from "./store/globalErrorStore.js";
import store from "./store/index.js";


axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  withXSRFToken: true,
  
});

axiosClient.interceptors.request.use(config => {
  let token = sessionStorage.getItem('TOKEN');
  config.headers.Authorization = `Bearer ${token}`
  return config;
});

axiosClient.interceptors.response.use(response => {
  return response;
}, error => {
  const globalError = useGlobalErrorStore();

  if(error.response) {
    if (error.response.status === 401) { // Unauthorized 
      store.commit('setToken', null);
      //globalError.setError();
      //sessionStorage.setItem('global_program_error', true);

      router.push({name: 'login'});
    }
    throw error;
  } else {
    console.log("error:");
    console.log(error);
    globalError.setError();
    sessionStorage.setItem('global_program_error', true);
    
    throw error;
  }
});

export default axiosClient;
